<template>
  <page>
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div style="padding: 15px;">
        <div class="f_flex f_a_c f_j_sb underline">
          <input type="text" placeholder="请输入" v-model="labelName" class="labelNameInput f_g1">
          <div class="theme_c" @click="createLabel">添加</div>
        </div>
        <div style="height: 15px;"></div>
        <div class="f_flex f_w">
          <div 
            class="labelItem f_flex f_a_c f_j_c"
            v-for='(item, index) in get_labelList' 
            :key='index'>
            <i class="iconfont icon-close iconClose" @click='deleteItem(index)'></i>
            {{item.labelName}}
          </div>
        </div>

        <button @click="submitFn" style="width: 80%; margin-top: 126px" class="btn btn_radius theme_bg margin_auto">确定</button>
      </div>
    </div>
  </page>
</template>

<script>
import styleNoApi from '@/api/styleNo'
import {mapActions, mapGetters} from 'vuex'

export default {
  data: ()=> ({
    labelName: '',
    labelList: [],
  }),
  computed: {
    ...mapGetters([
      'get_labelList'
    ])
  },
  methods: {
    ...mapActions([
      'set_labelList'
    ]),
    addLabel() {
      if (this.labelName) {
        this.labelList.push({
          labelName: this.labelName.trim(),
        })
        this.labelName = ''
      } else {
        this.$toast('标签名不能为空')
      }
    },
    deleteItem(index) {
      this.labelList.splice(index, 1)
      this.set_labelList(this.labelList)
    },
    createLabel() {
      const {companyId, postsSource} = this.$route.query
      if (this.labelName) {
        const companyInfo = this.$global.getItem('companyInfo', true)
        const obj = {
          labelSource: Number(postsSource),
          bizId: companyId || companyInfo?.companyId,
          labelName: this.labelName
        }
        styleNoApi.labelCreate(obj).then(res => {
          if (res.data) {
            this.$toast(res.data.msg)
            
            // {id, labelName， labelGroupName} 修改格式保持和分页查询统一 
            this.labelList.push({
              labelName: this.labelName.trim(),
              labelId: res.data.bizId,
              labelGroupName: ''
            })
            setTimeout(()=> {
              this.labelName = ''
            }, 20)
            this.set_labelList(this.labelList)
          }
        })
      } else {
        this.$toast('请输入标签')
      }
    },
    submitFn() {
      if (this.labelList.length) {
        this.set_labelList(this.labelList)
        setTimeout(() => {
          this.$router.back()
        }, 20)
      } else {
        this.$toast('标签不能为空')
      }
    }
  }
}
</script>


<style lang="less" scoped>
.labelNameInput {
  border: none;
  outline: none;
  height: 50px;
  font-size: 14px;
}

.labelItem {
  position: relative;
  padding: 10px 20px;
  min-width: 100px;
  background: #F1F1F1;
  box-sizing: border-box;
  margin: 5px;
  .iconClose {
    color: #bebebe;
    font-size: 14px;
    position: absolute;
    left: 4px;
    top: 4px;
  }
}
</style>